import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import IBadgesSettings from '../../../types/api/Admin/Badges/Badges';

export async function getBadgesSettings(): Promise<AxiosResponse<IBadgesSettings[]>> {
  return makeRequest('GET', '/api/banking/admin/pure-utilities/badges-settings');
}

export async function setBadgesSettings({
  rarity, maxCharges, matchMinutesPerCharge, chargeCost, chargeSpeedupCost, chargeTimeSeconds,
  craftCost, previousBadgesToCraft, craftTimeSeconds, rewardMultiplier, rewardMultiplierDecimals
}: IBadgesSettings) {
  return makeRequest('POST', '/api/banking/admin/pure-utilities/badges-settings', {
    rarity, maxCharges, matchMinutesPerCharge, chargeCost, chargeSpeedupCost, chargeTimeSeconds,
    craftCost, previousBadgesToCraft, craftTimeSeconds, rewardMultiplier, rewardMultiplierDecimals
  });
}
