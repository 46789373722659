import {AxiosResponse} from 'axios';

import makeRequest from '../../request';

import IContractsSettings, {
  IBonusesSettings, IEditBonusesSettings,
  IEditContractsSettings
} from '../../../types/api/Admin/Contracts/Contracts';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';

export async function getContractsSettings(): Promise<AxiosResponse<IContractsSettings[]>> {
  return makeRequest('GET', '/api/banking/admin/pure-utilities/contracts-settings');
}

export async function setContractsSettings({
  rarity, maxLevel, priceA, priceADecimals, priceB, priceBDecimals, priceC, priceCDecimals
}: IEditContractsSettings) {
  return makeRequest('POST', '/api/banking/admin/pure-utilities/contracts-settings', {
    rarity, maxLevel, priceA, priceADecimals, priceB, priceBDecimals, priceC, priceCDecimals
  });
}

export async function getBonusesSettings(): Promise<AxiosResponse<IBonusesSettings[]>> {
  return makeRequest('GET', '/api/banking/admin/pure-utilities/contracts-bonuses-settings');
}

export async function setBonusesSettings({
  type, weight, name, startBonusLvl, startBonusLvlDecimals, bonusLvlStep, bonusLvlStepDecimals, amount
}: IEditBonusesSettings) {
  return makeRequest('POST', '/api/banking/admin/pure-utilities/contracts-bonuses-settings', {
    type, weight, name, startBonusLvl, startBonusLvlDecimals, bonusLvlStep, bonusLvlStepDecimals, amount
  });
}

export async function deleteBonusesSettings(toDrop: number, replaceBy: number) {
  await makeRequest('DELETE', '/api/banking/admin/pure-utilities/contracts-bonuses-settings', {toDrop, replaceBy});
}

export async function getBonusesSettingsTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return await makeRequest('GET', '/api/banking/admin/pure-utilities/contracts-bonuses-settings/types');
}

export async function rollbackUnpacks(openlootUserId: string) {
  return await makeRequest('POST', '/api/banking/admin/pure-utilities/contracts/rollback-unpacks', {openlootUserId});
}